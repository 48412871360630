export const comments = {
  ja: {
    score: {
      up: '生活習慣スコアは7月より%d点上がりました。この調子です！<br>９月のテーマは、<b>❝『歩く』魅力を大解剖❞​</b> です。いよいよ9月20日から<b>Walk チーム対抗戦 2023 2nd​</b>が始まります。『歩く』ということは、日常動作だけではなく、体力UPやストレス解消など、様々な効果が期待できます。歩くことの魅力を知り、対抗戦をより一層楽しみましょう。',
      down: '生活習慣スコアは7月より%d点下がりました。食事・運動など、どの項目に変化がありましたか？<br>９月のテーマは、<b>❝『歩く』魅力を大解剖❞​ ​</b> です。いよいよ9月20日から<b>Walk チーム対抗戦 2023 2nd​</b>が始まります。『歩く』ということは、日常動作だけではなく、体力UPやストレス解消など、様々な効果が期待できます。歩くことの魅力を知り、対抗戦をより一層楽しみましょう。',
      equal100:
        '生活習慣スコアは7月に引き続き%満点%です。大変素晴らしいです！<br>９月のテーマは、<b>❝『歩く』魅力を大解剖❞​ ​</b> です。いよいよ9月20日から<b>Walk チーム対抗戦 2023 2nd​</b>が始まります。『歩く』ということは、日常動作だけではなく、体力UPやストレス解消など、様々な効果が期待できます。歩くことの魅力を知り、対抗戦をより一層楽しみましょう。',
      equal:
        '生活習慣スコアは7月と同じ点数です。<br>９月のテーマは、<b>❝『歩く』魅力を大解剖❞​ ​</b> です。いよいよ9月20日から<b>Walk チーム対抗戦 2023 2nd​</b>が始まります。『歩く』ということは、日常動作だけではなく、体力UPやストレス解消など、様々な効果が期待できます。歩くことの魅力を知り、対抗戦をより一層楽しみましょう。',
    },
    steps: {
      up: '歩数スコアは7月より%d点上がりました。<br>良く歩けていますね。こちらの<a href="https://go.andwell.jp/library/c798f14f-e58a-11ed-b977-0ab1e6a5e015">＜動画＞</a>では、「楽」な歩き方を伝授！',
      down: '歩数スコアは7月より%d点下がりました<br>10分歩くと約1000歩と言われています。10分まとめて、もしくは5分×２回歩くだけで、今より1000歩歩数が増やせますよ。',
      equal:
        '歩数スコアは7月と同じ点数です。<br>10分歩くと約1000歩と言われています。10分まとめて、もしくは5分×２回歩くだけで、今より1000歩歩数が増やせますよ。',
    },
    exercise: {
      up: '運動のスコアは7月より%d点上がりました。<br>身体を動かす機会が増えていますね！自分のペースで続けていくことも大切です♪',
      down: '運動のスコアは7月より%d点下がりました。<br>時間がない方や続けるのが難しい方に、「ながら運動」がオススメ。<b>テレビを見ながらストレッチ</b>や、<b>スマホを見ながらかーフレイズ（かかと上げ下げ）</b>など、試してみくて下さい♪',
      equal:
        '運動のスコアは7月と同じ点数です。<br>時間がない方や続けるのが難しい方に、「ながら運動」がオススメ。<b>テレビを見ながらストレッチ</b>や、<b>スマホを見ながらかーフレイズ（かかと上げ下げ）</b>など、試してみくて下さい♪',
    },
    meal: {
      up: '食事のスコアは7月より%d点上がりました。<br>夏から秋の変わり目に体調を崩しやすい方は、「保温」と「栄養摂取」を心がけて。温かい飲み物や食べ物で体を芯から温めましょう。',
      down: '食事のスコアは7月より%d点下がりました。<br>夏から秋の変わり目に体調を崩しやすい方は、「保温」と「栄養摂取」を心がけて。温かい飲み物や食べ物で体を芯から温めましょう。',
      equal:
        '食事のスコアは7月と同じ点数です。<br>夏から秋の変わり目に体調を崩しやすい方は、「保温」と「栄養摂取」を心がけて。温かい飲み物や食べ物で体を芯から温めましょう。',
    },
    drinking: {
      up: '飲酒のスコアは7月より%d点上がりました。<br>夏場もお酒と上手くお付き合いできていましたね。食欲の秋も引き続き、この調子で継続していきましょう。',
      down: '飲酒のスコアは7月より%d点下がりました。<br>飲み過ぎは体内リズムを乱す原因に。寝る4～5時間前までに適量を飲み終える日を作ってみましょう。',
      equal:
        '飲酒のスコアは7月と同じ点数です。<br>飲み過ぎは体内リズムを乱す原因に。寝る4～5時間前までに適量を飲み終える日を作ってみましょう。',
    },
    sleep: {
      up: '睡眠のスコアは7月より%d点上がりました。<br>睡眠は、脳や体の疲れをとったり、傷ついた細胞を修復するという大切な役割があります。この調子で、ダルさ知らずの身体を手に入れよう。',
      down: '睡眠のスコアは7月より%d点下がりました。<br>日中の眠気を感じる日が多くなっていますね。しっかり眠れていますか？ゆっくり湯船に浸かって、リラックスするのも快眠に繋がります。',
      equal:
        '睡眠のスコアは7月と同じ点数です。<br>しっかり眠れていますか？ゆっくり湯船に浸かって、リラックスするのも快眠に繋がります。',
    },
    stress: {
      up: 'ストレスのスコアは7月より%d点上がりました。<br>人間関係を円滑にする「<b>アサーション</b>」というコミュニケーションスキルはご存じですか？こちらの<a href="https://go.andwell.jp/library/c299eb3f-0b20-11ee-8f80-0ab1e6a5e015">＜コラム＞</a>で、実行に移すための3つのステップをお伝え！',
      down: 'ストレスのスコアは7月より%d点下がりました。<br>人間関係を円滑にする「<b>アサーション</b>」というコミュニケーションスキルはご存じですか？こちらの<a href="https://go.andwell.jp/library/c299eb3f-0b20-11ee-8f80-0ab1e6a5e015">＜コラム＞</a>で、実行に移すための3つのステップをお伝え！',
      equal:
        'ストレスのスコアは7月と同じ点数です。<br>人間関係を円滑にする「<b>アサーション</b>」というコミュニケーションスキルはご存じですか？こちらの<a href="https://go.andwell.jp/library/c299eb3f-0b20-11ee-8f80-0ab1e6a5e015">＜コラム＞</a>で、実行に移すための3つのステップをお伝え！',
    },
  },
  en: {
    score: {
      up: `Your lifestyle Score has gone up by %d points since July. Keep it up!<br>
      The theme for September is <b>"Walking: A Complete Dissection of the Appeal of Walking</b>". Finally, the <b>Team Walk Competition 2023 2nd</b> will start on September 20th. Walking is not only a daily activity, but also a way to improve physical fitness and relieve stress. Let's learn more about the appeal of walking and enjoy the competition that much more.`,
      down: `Your lifestyle score has dropped by %d points since July. Has your diet or exercise changed?<br>
      The theme for September is <b>"Walking: A Complete Dissection of the Appeal of Walking</b>". Finally, the <b>Team Walk Competition 2023 2nd</b> will start on September 20th. Walking is not only a daily activity, but also a way to improve physical fitness and relieve stress. Let's learn more about the appeal of walking and enjoy the competition that much more.`,
      equal100: `Your Lifestyle Score is perfect, just as it was in July. Great work!<br>
      The theme for September is <b>"Walking: A Complete Dissection of the Appeal of Walking</b>". Finally, the <b>Team Walk Competition 2023 2nd</b> will start on September 20th. Walking is not only a daily activity, but also a way to improve physical fitness and relieve stress. Let's learn more about the appeal of walking and enjoy the competition that much more.`,
      equal: `Your Lifestyle Score is the same as July.<br>
      The theme for September is <b>"Walking: A Complete Dissection of the Appeal of Walking</b>". Finally, the <b>Team Walk Competition 2023 2nd</b> will start on September 20th. Walking is not only a daily activity, but also a way to improve physical fitness and relieve stress. Let's learn more about the appeal of walking and enjoy the competition that much more.`,
    },
    steps: {
      up: 'Your steps score has gone up %d pts since July.<br>You\'re good at walking! This <a href="https://go.andwell.jp/library/c798f14f-e58a-11ed-b977-0ab1e6a5e015">video</a> will teach you how to walk even more easily!',
      down: "Your steps score has gone down %d pts since July. <br>It's said that a 10-minute walk is about 1,000 steps. Just do a 10-minute walk, or two 5-minute walks, and you'll get 1,000 more steps.",
      equal:
        "Your steps score is the same as July. <br>It's said that a 10-minute walk is about 1,000 steps. Just do a 10-minute walk, or two 5-minute walks, and you'll get 1,000 more steps.",
    },
    exercise: {
      up: "Your exercise score has gone up %d pts since July.<br>There are now more and more opportunities to move your body! It's important to continue at your own pace♪",
      down: 'Your exercise score has gone down %d pts since July.<br>For those without much time or who find it hard to continue, we suggest "multitask excercising". Try <b>stretching while watching TV</b>, or <b>doing calf raises while looking at your phone</b>.',
      equal:
        'Your exercise score is the same as July.<br>For those without much time or who find it hard to continue, we suggest "multitask excercising". Try <b>stretching while watching TV</b>, or <b>doing calf raises while looking at your phone</b>.',
    },
    meal: {
      up: 'Your food score has gone up %d pts since July.<br>If you get sick easily during the change from summer to fall, remember to stay warm and get plenty of nutrients. Warm yourself with warm drinks and food.',
      down: 'Your food score has gone down %d pts since July.<br>If you get sick easily during the change from summer to fall, remember to stay warm and get plenty of nutrients. Warm yourself with warm drinks and food.',
      equal:
        'Your food score is the same as July.<br>If you get sick easily during the change from summer to fall, remember to stay warm and get plenty of nutrients. Warm yourself with warm drinks and food.',
    },
    drinking: {
      up: 'Your alcohol score has gone up %d pts since July.<br>You did a good job controlling how much alcohol you drank this summer. Keep it up this fall, the season of food.',
      down: "Your alcohol score has gone down %d pts since July.<br>Drinking too much can disturb your body's rhythm. Plan some days to drink an appropriate amount no less than four to five hours before you sleep.",
      equal:
        "Your alcohol score is the same as July.<br>Drinking too much can disturb your body's rhythm. Plan some days to drink an appropriate amount no less than four to five hours before you sleep.",
    },
    sleep: {
      up: 'Your sleep score has gone up %d pts since July.<br>Sleep plays an important role in relieving the brain and body of fatigue and repairing damaged cells. Get good sleep and your body will be free of sluggishness.',
      down: "Your sleep score has gone down %d pts since July.<br>You are feeling sleepy during the day more and more often. Are you getting a good night's sleep? Slowly soaking in the bathtub and relaxing will also help you sleep well.",
      equal:
        "Your sleep score is the same as July.<br>Are you getting a good night's sleep? Slowly soaking in the bathtub and relaxing will also help you sleep well.",
    },
    stress: {
      up: 'Your stress score has gone up %d pts since July.<br>Have you ever heard of the communication skill called "<b>assertion</b>" which helps promote balanced relationships? In <a href="https://go.andwell.jp/library/c299eb3f-0b20-11ee-8f80-0ab1e6a5e015">this column</a>, we\'ll give you three steps you can try!',
      down: 'Your stress score has gone down %d pts since July.<br>Have you ever heard of the communication skill called "<b>assertion</b>" which helps promote balanced relationships? In <a href="https://go.andwell.jp/library/c299eb3f-0b20-11ee-8f80-0ab1e6a5e015">this column</a>, we\'ll give you three steps you can try!',
      equal:
        'Your stress score is the same as July.<br>Have you ever heard of the communication skill called "<b>assertion</b>" which helps promote balanced relationships? In <a href="https://go.andwell.jp/library/c299eb3f-0b20-11ee-8f80-0ab1e6a5e015">this column</a>, we\'ll give you three steps you can try!',
    },
  },
}
